<template>
  <div role="tablist">
    <div class="container title-order d-none d-md-block">
      <div class="row ">
        <div class="col-sm">מספר הזמנה</div>
        <div class="col-sm">תאריך הזמנה</div>
        <div class="col-sm">סה"כ להזמנה</div>
        <div class="col-sm">סטטוס הזמנה</div>
        <div class="col-sm"></div>
        <div class="col-sm">חשבונית</div>
      </div>
    </div>

    <div v-for="(item, index) of items" :key="index">
      <OrderRow :item="item" :keys="index" :mobile="mobile" />
    </div>
  </div>
</template>


<script>
import OrderRow from "@/components/profile/OrderRow";
export default {
  name: "TableDesktop",

  props: ["items", "mobile"],

  components: {
    OrderRow
  }
};
</script>

<style scoped>
.title-order {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  direction: rtl;
}

.title-order .row {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;

  border-top: 1px solid #d7d7d7;
}
</style>
