<template>
  <div class="profile-info">
    <div class="profile-header">
      <h4 class="profile-title" v-text="'פרטים אישיים'" />
    </div>

    <div class="profile-info">
      <div class="profile-item">
        <span v-text="':שם פרטי'" />
        <span v-text="profile.fname || ''" />
      </div>

      <div class="profile-item">
        <span v-text="':שם משפחה'" />
        <span v-text="profile.lname || ''" />
      </div>

      <div class="profile-item">
        <span v-text="':כתובת דואר אלקטרוני'" />
        <span v-text="profile.mail || ''" />
      </div>

      <div class="profile-item">
        <span v-text="':טלפון'" />
        <span v-text="profile.phone || ''" />
      </div>

      <div class="profile-item">
        <span v-text="':עיר'" />
        <span v-text="profile.address || ''" />
      </div>

      <div class="profile-item">
        <span v-text="':רחוב'" />
        <span v-text="profile.street || ''" />
      </div>

      <div class="profile-item">
        <span v-text="':מספר'" />
        <span v-text="profile.houseNum || ''" />
      </div>

      <div class="profile-item">
        <span v-text="':מספר דירה'" />
        <span v-text="profile.diraNum || ''" />
      </div>

      <div class="profile-item" v-if="birthDate">
        <span v-text="':תאריך יום הולדת'" />
        <span v-text="birthDate" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileInfo',

  computed:{
    profile(){
      return this.$store.getters.user
    },

    birthDate(){
      if(this.profile.day && this.profile.month && this.profile.year ) {
        return this.profile.day + '/' + this.profile.month + '/' + this.profile.year
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.profile-item{
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: .5rem;
}
.profile-item span:first-child{
  margin-left: .5rem;
}
.profile-item span:last-child{
  color: #8f8888;
}
.profile-header{
  padding-top: .7rem;
  margin-bottom: 2.25rem;
}
.profile-title{
  text-align: right;
  font-weight: bold;
  font-size: .8rem;
}
@include media-breakpoint-down(sm) {
  .profile-item{
    font-size: .8rem;
    margin-bottom: .35rem;
  }
}
</style>
