import { render, staticRenderFns } from "./TableMobile.vue?vue&type=template&id=56e3f367&scoped=true&"
import script from "./TableMobile.vue?vue&type=script&lang=js&"
export * from "./TableMobile.vue?vue&type=script&lang=js&"
import style0 from "./TableMobile.vue?vue&type=style&index=0&id=56e3f367&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56e3f367",
  null
  
)

export default component.exports