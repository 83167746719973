<template>
  <div id="table-mobile">
    <div class="cart-mobile" v-for="item of items" @click="item.visible = !item.visible">
      <div class="cart-row">
        <span>מספר הזמנה</span>
        <span>{{item.order_number}}</span>
      </div>

      <div class="cart-row">
        <span>תאריך הזמנה</span>
        <span>{{item.order_date}}</span>
      </div>

      <div class="cart-row">
        <span>סה"כ להזמנה</span>
        <span>{{item.total_order}}</span>
      </div>

      <div class="cart-row">
        <span>סטטוס הזמנה</span>
        <span>{{item.order_status}}</span>
      </div>

      <a href="#" class="open-cart-mobile">
        לצפייה בחשבונית
      </a>

      <div class="cart-item-info" v-if="item.visible">
        <div class="small-cart">
          <div class="small-cart-body">
            <CardProductOrder
              v-for="(cartItem, index) of cart"
              :key="index"
              :cart="cartItem"
              :changeQuantity="false"
              :canDelete="false"
            />
          </div>
        </div>

        <div class="cart-total">
          <div class="cart-total-body">
            <div class="cart-checkout intermediate-summary">
              <p><span class="shekel">₪</span> 80</p>
              <p>סיכום ביניים ( 2 מוצרים)</p>
            </div>

            <div class="cart-checkout total-payment">
              <p><span class="shekel">₪</span> 80</p>
              <p><b>סה"כ לתשלום</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainMixin from '@/mixins/main.mixin'
import CardProductOrder from '@/components/CardProductOrder'

export default {
  name: 'TableMobile',

  mixins: [mainMixin],

  props: ['items'],

  components: {
    CardProductOrder
  },

  computed: {
    cart() {
      return this.$store.state.cart.cart
    }
  },

  async created() {
    await this.$store.dispatch('getCart')
  }
}
</script>

<style scoped>
.cart-mobile{
  border-top: 1px solid #d7d7d7;
  padding-top: .7rem;
  padding-bottom: .5rem;
  text-align: right;
}
#table-mobile .cart-mobile:last-child{
  border-bottom: 1px solid #d7d7d7;
}
.cart-row{
  font-size: .7rem;
  margin-bottom: .2rem;
}
.cart-row span:first-child{
  margin-left: .5rem;
}
.open-cart-mobile{
  font-size: 1rem;
  color: #000;
  display: block;
  margin-top: .4rem;
  text-decoration: underline;
}
.open-cart-mobile:hover{
  text-decoration: none;
}
.small-cart{
  border: none;
}
.small-cart-body{
  height: inherit;
  overflow-y: hidden;
}
.cart-good{
  padding-right: 0;
  border-bottom: none;
  justify-content: flex-end;
}
.good-media{
  width: 7.6rem;
}
.good-info-title {
  font-size: .8rem;
}
.good-info p{
  font-size: .8rem;
}
.cart-total{
  padding-top: 1.3rem;
  border-top: 1px solid #d7d7d7;
}
.cart-checkout p{
  font-size: .7rem;
}
</style>
