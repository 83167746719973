<template>
  <div class="form-container">
    <div class="form-header">
      <h2 class="form-title" v-text="title" />
    </div>

    <div class="form-body">
      <form class="gong-form" @submit.prevent="checkData" autocomplete="off" method="post">
        <div class="form-row">
          <div v-for="(input, index) of inputs" :key="index" :class="['form-group', input.cols]" v-if="input.visible">
            <input v-if="input.name === 'birthday'"
              :type="input.type"
              :name="input.name"
              @focus="deleteErrorServer(input.name)"
              :placeholder="input.placeholder"
              :class="['form-control', {'is-invalid': ($v.form[input.name] ? $v.form[input.name].$invalid && send : false)}]"
              v-model.trim="form[input.name]"
              v-mask="'##/##/####'"
            >

            <input
              v-else
              :type="input.type"
              :name="input.name"
              @focus="deleteErrorServer(input.name)"
              :placeholder="input.placeholder"
              :class="['form-control', {'is-invalid': ($v.form[input.name] ? $v.form[input.name].$invalid && send : false)}]"
              v-model.trim="form[input.name]"
            >

            <template v-if="$v.form[input.name]">
              <div class="invalid-feedback" v-if="!$v.form[input.name].required" v-text="input.errorRequired" />

              <template v-if="$v.form[input.name].required">
                <div class="invalid-feedback" v-if="$v.form[input.name].email && !$v.form[input.name].email">אנא הקלד כתובת דואר אלקטרוני תקינה</div>
                <div class="invalid-feedback" v-if="$v.form[input.name].minLength && !$v.form[input.name].minLength">
                  אנא הקלד לפחות {{ $v.form[input.name].$params.minLength.min }} תוים
                </div>
                <div class="invalid-feedback" v-if="$v.form[input.name].maxLength && !$v.form[input.name].maxLength">אנא הקד 10 ספרות לכל היותר</div>
                <div class="invalid-feedback" v-if="$v.form[input.name].numeric && !$v.form[input.name].numeric">אנא הקלד ספרות בלבד</div>
                <div class="invalid-feedback" v-if="$v.form[input.name].sameAsPassword && !$v.form[input.name].sameAsPassword">יש לחזור על הסיסמה שנבחרה</div>
              </template>
            </template>

            <div class="error-server" v-if="error && input.name in error" v-text="error[input.name]" />
          </div>
        </div>

        <div class="form-group" v-if="isRegister">
          <div class="input terms-input inline position-relative">
            <input type="checkbox" id="terms" v-model.trim="terms" class="terms-checkbox" />
            <label :class="['mr-4', {'checkbox-error': $v.terms.$invalid && send}]" for="terms">
              אני מאשר/ת את התקנון ומסכים/ה לקבל תוכן שיווקי בכל המדיות שמסרתי בהרשמה
            </label>
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-block" :disabled="disable">
            <div class="spinner-border text-light" role="status" v-if="loading">
              <span class="sr-only" v-text="'Loading...'" />
            </div>
            {{ isRegister ? 'הרשמה' : 'שמור שינויים' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { required, email, numeric, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'RegisterComponent',

  directives: { mask },

  props: ['title', 'isRegister'],

  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        birthday: '',
        email: '',
        phone: '',
        password: '',
        street: '',
        diraNum: '',
        houseNum: '',
        city: '',
      },
      send: false,
      terms: false
    }
  },

  computed: {
    profile() {
      return this.$store.getters.user
    },
    inputs() {
      return [
        {type: 'text', name: 'firstName', placeholder: 'שם פרטי', errorRequired: 'אנא הקלידי שם בפרטי', cols: this.isRegister ? 'col-12' : 'col-6', visible: true},
        {type: 'text', name: 'lastName', placeholder: 'שם משפחה', errorRequired: 'אנא הקלידי שם משפחה', cols: this.isRegister ? 'col-12' : 'col-6', visible: true},
        {type: 'text', name: 'birthday', placeholder: 'תאריך הלידה', errorRequired: '', cols: 'col-12', visible: true},
        {type: 'text', name: 'email', placeholder: 'מייל', errorRequired: 'יש להזין כתובת מייל', cols: 'col-12', visible: true},
        {type: 'number', name: 'phone', placeholder: 'טלפון', errorRequired: 'יש להזין מספר טלפון', cols: 'col-12', visible: true},
        {type: 'password', name: 'password', placeholder: 'סיסמה', errorRequired: 'עלייך ליצר סיסמה אישית', cols: 'col-12', visible: this.isRegister},
        {type: 'password', name: 'passwordrepeat', placeholder: 'חזור על הסיסמה', errorRequired: 'אנא הקלידי שם בפרטי', cols: 'col-12', visible: this.isRegister},
        {type: 'text', name: 'street', placeholder: 'רחוב', errorRequired: '', cols: 'col-12', visible: !this.isRegister},
        {type: 'text', name: 'diraNum', placeholder: 'מספר דירה', errorRequired: '', cols: 'col-6', visible: !this.isRegister},
        {type: 'text', name: 'houseNum', placeholder: 'מספר רחוב', errorRequired: '', cols: 'col-6', visible: !this.isRegister},
        {type: 'text', name: 'city', placeholder: 'עיר', errorRequired: '', cols: 'col-12', visible: !this.isRegister},
      ]
    },
    loading() {
      return this.$store.getters.authLoadingRegister
    },
    disable() {
      return this.$store.getters.buttonDisableRegister
    },
    error() {
      return this.$store.getters.errorRegister
    }
  },

  validations() {
    if(this.isRegister) {
      return {
        form: {
          firstName: { required },
          lastName: { required },
          email: { required, email },
          phone: { required, numeric, minLength: minLength(10), maxLength: maxLength(10) },
          password: { required, minLength: minLength(4) },
          passwordrepeat: { required, sameAsPassword: sameAs('password') },
        },
        terms: { sameAs: sameAs( () => true ) }
      }
    } else {
      return {
        form: {
          firstName: { required },
          lastName: { required },
          email: { required, required },
          phone: { required, numeric, minLength: minLength(10), maxLength: maxLength(10) },
        },
      }
    }
  },

  methods: {
    deleteErrorServer(inputName) {
      this.error ? delete this.error[inputName] : null
    },

    checkData() {
      this.send = true
      this.$v.$touch()
      if (this.$v.$invalid) return false

      this.isRegister ? this.registration() : this.updateUser()
    },

    async registration() {
      const form = new FormData()

      const birthdaySplit = this.form.birthday.split('/')
      form.append('day', birthdaySplit[0])
      form.append('month', birthdaySplit[1])
      form.append('year', birthdaySplit[2])
      form.append('firstName', this.form.firstName)
      form.append('lastName', this.form.lastName)
      form.append('email', this.form.email)
      form.append('phone', this.form.phone)
      form.append('password', this.form.password)

      await this.$store.dispatch('registration', { form })

      if(this.error === '') {
        if(this.$route.params.fromCheckout) {
          this.$router.push({name:'checkout'})
        } else if(this.$route.params.favoritePage) {
          this.$router.push({name:'favorites'})
        } else if(this.$route.params.favorite !== undefined) {
          await this.$store.dispatch('updateFavProduct', {
            productId: this.$route.params.favorite.productId
          })

          this.$router.push({
            name:'product',
            params: {
              subCategoryId: this.$route.params.favorite.subCategoryId,
              productId: this.$route.params.favorite.productId,
            }
          })
        } else {
          this.$router.go(-1)
        }
      }
    },

    async updateUser() {
      const form = new FormData()

      const birthdaySplit = this.form.birthday.split('/')
      form.append('day', birthdaySplit[0])
      form.append('month', birthdaySplit[1])
      form.append('year', birthdaySplit[2])
      form.append('fname', this.form.firstName)
      form.append('lname', this.form.lastName)
      form.append('email', this.form.email)
      form.append('phone', this.form.phone)
      form.append('street', this.form.street)
      form.append('diraNum', this.form.diraNum)
      form.append('houseNum', this.form.houseNum)
      form.append('city', this.form.city)

      await this.$store.dispatch('updateUser', { form })
    }
  },

  created() {
    if(this.profile.fname !== undefined) {
      this.form.firstName = this.profile.fname
      this.form.lastName = this.profile.lname
      this.form.email = this.profile.mail
      this.form.phone = this.profile.phone
      this.form.street = this.profile.street
      this.form.diraNum = this.profile.diraNum
      this.form.houseNum = this.profile.houseNum
      this.form.city = this.profile.address
      this.form.birthday = (this.profile.day + '/' + this.profile.month + '/' + this.profile.year || '')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.error-server {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  text-align: right;
}
.checkbox-error{
  color:  #dc3545;
}
.terms-input{
  direction: rtl;
  text-align: right;
}
.terms-checkbox{
  margin-left: 0.8rem;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 4px;
  right: 0;
}
#profile .form-header {
  padding-top: .7rem;
  margin-bottom: 2.25rem;
}
#profile .form-title {
  text-align: right;
  direction: rtl;
  font-weight: 700;
  font-size: .8rem;
}
.form-control, .invalid-feedback{
  direction: rtl;
}
#profile .form-container {
  padding-bottom: 0;
}

@include media-breakpoint-down(sm) {
  .form-header{
    margin-bottom: 1.5rem;
  }
  .form-title{
    font-size: 1.2rem;
  }
  .gong-form .form-group .form-control {
    height: 3.4rem;
    font-size: .8rem;
  }
  #innerLayout .form-container .form-group .btn {
    height: inherit;
    font-size: 1rem;
    line-height: 2;
  }
  #innerLayout .form-container .form-group .spinner-border{
    vertical-align: middle;
    margin-right: .5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
</style>
