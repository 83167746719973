<template>
  <div role="tablist">
    <b-card no-body class="mb-0">
      <header v-b-toggle="'accordion-' + keys" role="tab">
        <div class="container row-title-order">
          <div class="row">
            
           
            <div class="col text-right text-md-center">
              <span class="d-md-none">מספר הזמנה : </span>
            <span >{{item.id}}</span>
            </div>

            <div class="col-sm d-none d-md-block ">  {{ item.rdate | moment("DD/MM/YYYY") }}</div>
            <div class="col-sm d-md-none direction text-right">{{item.rdate | moment("DD/MM/YYYY") +' : תאריך הזמנה'}} </div>
            <div class="col-sm d-none d-md-block">{{item.totalPrice}} ₪</div>
            <div class="col-sm d-md-none direction text-right">{{item.totalPrice}} ₪ : סה"כ להזמנה </div>
            <div class="col-sm d-none d-md-block">-</div>
            <div class="col-sm d-md-none direction text-right">- : סטטוס הזמנה</div>
            <div class="col-sm d-none d-md-block">לצפייה בהזמנה</div>
            <div class="col-sm d-none d-md-block ">
              <span class="icons icon-order"></span>
              <a :href="item.invoicing">לצפייה בחשבונית</a>
            </div>
            <div class="col-sm d-md-none direction text-right ">
              <span class="icons icon-order"></span>
              <a :href="item.invoicing">לצפייה בחשבונית</a>
            </div>
          </div>
        </div>
      </header>
      <b-collapse :id="'accordion-'+keys" accordion="my-accordion" role="tabpanel">
        <OrderRowDetail :item="item" />
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import OrderRowDetail from "@/components/profile/OrderRowDetail";
export default {
  name: "OrderRow",

  props: ["item", "keys", "mobile"],

  components: {
    OrderRowDetail
  }
};
</script>


<style scoped>
.row-title-order {
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  direction: rtl;
}

.direction{
  direction: initial;;
}

.row-title-order .row {
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  border-top: 1px solid #d7d7d7;
  background: #fff;
}

.row-title-order .row .col-sm {
  align-self: center;
}

.row-title-order a {
  color: #000;
}
</style>