<template>
    <b-card-body class="row-details">

        <div class="row align-items-md-center" v-for="(product, index) of item.products" :key="index"> 

            <div class="col-4 col-md-2">
                <div class="row">
                    <div class="col-12" >
                         <img :src="'https://www.crm.gong-online.co.il/'+product.pic"  alt="" class="img-fluid image" >
                    </div>
                </div>
            </div>

              <div class="col">
                <div class="row text-right text-md-center infos" >
                    <div class="col-12 col-md infos-title">
                          {{product.catalog[0].titleHEB}}
                    </div>
                    <div class="col-12 col-md">
                            <span class="d-md-none"> צבע : </span>
                             {{product.color.title}}
                    </div>
                    <div class="col-12 col-md">
                          <span class="d-md-none">מדה : </span>
                           {{product.size.title}}
                    </div>
                    <div class="col-12 col-md">
                        <span class="d-md-none">כמות :</span>
                         {{product.amount}}
                    </div>
                    <div class="col-12 col-md  price">
                        <span class="d-md-none">מחיר : </span>
                      {{product.price}} ₪
                    </div>
                </div>
            </div>
            
        </div> 
        <div class="order-total-price">
        <div class="row">
            <div class="col-md d-sm-none d-md-block"></div>
            <div class="col-md d-sm-none  d-md-block"></div>
            <div class="col-md d-sm-none  d-md-block"></div>
            <div class="col-md text-right d-none  d-md-block ">סיכום ביניים ( {{item.products.length}} מוצרים)</div>
            <div class="col-md d-sm-none  d-md-block"></div>
            <div class="col-md  d-none d-md-block ">{{item.originalPrice}} ₪ </div>

            <div class="col-md d-md-none d-flex justify-content-between ">
                <span>סיכום ביניים ( {{item.products.length}} מוצרים)</span>
                <span>{{item.originalPrice}} ₪ </span>
            </div>
        </div>




         <div class="row">
            <div class="col-md d-sm-none d-md-block"></div>
            <div class="col-md d-sm-none  d-md-block"></div>
            <div class="col-md d-sm-none  d-md-block"></div>
            <div class="col-sm text-right  d-none d-md-block">קופון</div>
             <div class="col-md d-sm-none  d-md-block"></div>
            <div class="col-sm  d-none d-md-block ">0 ₪</div>

             <div class="col-md d-md-none d-flex justify-content-between ">
                <span>קופון</span>
                <span>0 ₪ </span>
            </div>


        </div>
           <div class="row">
            <div class="col-md d-sm-none d-md-block"></div>
            <div class="col-md d-sm-none  d-md-block"></div>
            <div class="col-md d-sm-none  d-md-block"></div>
            <div class="col-sm text-right  d-none d-md-block">{{item.delivery[0].title}}</div>
            <div class="col-md d-sm-none  d-md-block"></div>
            <div class="col-sm  d-none d-md-block"> {{item.delivery[0].price}} ₪ </div>

             <div class="col-md d-md-none d-flex justify-content-between ">
                <span>{{item.delivery[0].title}}</span>
                <span>{{item.delivery[0].price}} ₪ </span>
            </div>

        </div>
      

        <div class="row">
            <div class="col-md d-sm-none d-md-block"></div>
            <div class="col-md d-sm-none  d-md-block"></div>
            <div class="col-md d-sm-none  d-md-block"></div>
            <div class="col-sm text-right  d-none d-md-block">סה"כ לתשלום</div>
            <div class="col-md d-sm-none  d-md-block"></div>
            <div class="col-sm  d-none d-md-block"> {{item.totalPrice}} ₪</div>

             <div class="col-md d-md-none d-flex justify-content-between ">
                <span>סה"כ לתשלום</span>
                <span>{{item.totalPrice}} ₪ </span>
            </div>
        </div>
        </div>
       
    </b-card-body>
     
</template>

<script>

export default {
  name: 'OrderRowDetail',

props: ['item'],
 

}
</script>


<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.row-details{
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
    direction: rtl;
     border-top: 1px solid #D7D7D7
}

.row-details .row img{
    padding-bottom: 1rem;
}

.row-title-order .row{
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  border-top: 1px solid #D7D7D7;
  background: #FFF;
}

.row-details .row .col-sm{
 align-self: center;
}

.order-total-price .row .col-sm{
    direction: rtl;
}

.row-title-order a {
    color: #000;
}

.order-total-price .row {
    font-size: 0.9rem;
    font-weight: bold;
    padding-top: 0.3rem;
}
.image{
    width: 50%;
}


@include media-breakpoint-down(sm) {
    .infos > div{
        padding-top:0.1rem;
        padding-bottom:0.1rem;
        line-height: 14px;
    }



    .infos-title {
        padding-top:0.5rem !important; 
        padding-bottom:0.5rem !important; 
    }
    .order-total-price {
        border-top: 1px solid #e4e4e4;
    padding-top: 1rem;
    }
    .price{
        padding-top: 0.5rem !important;
    }
    .image{
     width: 100%;
    }
}

</style>