<template>
  <div id="profile">
    <section class="site-section">
      <div class="container-fluid">
        <div class="section-header">
          <h2 class="section-title" v-text="'החשבון שלי'" />
        </div>

        <div class="section-body">
          <div class="row">
            <div class="col-md-8">

              <div class="orders">
                <div class="order-header">
                  <router-link to="/" class="btn btn-primary d-none" v-text="'₪ 20 :סכום זיכוי בחשבון'" />

                  <h3 class="order-title" v-text="'ההזמנות שלי'" />
                </div>

                <div class="order-body">
                  1
                  <TableDesktop :items="orders" :mobile="device.mobile()" />
                  2
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="profile-container">
                <div class="profile-body">
                  <ProfileInfo v-if="toggleProfile" />

                  <RegisterComponent title="פרטים אישיים" :isRegister="false" v-if="toggleEdit" />

                  <ProfilePassword v-if="togglePassword" />
                </div>

                <div class="profile-footer">
                  <a href="#" @click.prevent="showEdit" v-if="toggleProfile" v-text="'עריכת פרטים אישיים'" />

                  <a href="#" @click.prevent="showPassword" v-if="toggleProfile" v-text="'שינוי סיסמה'" />

                  <a href="#" @click.prevent="showInfo" v-if="toggleEdit || togglePassword" v-text="'לחזור'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import device from 'current-device'
import ProfileInfo from '@/components/profile/ProfileInfo'
import RegisterComponent from '@/components/auth/RegisterComponent'
import ProfilePassword from '@/components/profile/ProfilePassword'
import TableDesktop from '@/components/profile/TableDesktop'
import TableMobile from '@/components/profile/TableMobile'

export default {
  name: 'Profile',

  components: {
    ProfileInfo, RegisterComponent, TableDesktop, TableMobile, ProfilePassword
  },

  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME+' | החשבון שלי | '+process.env.VUE_APP_NAME_HEB
    }
  },

  data() {
    return {
      device,
      visible: false,
      toggleProfile: true,
      toggleEdit: false,
      togglePassword: false,
    }
  },

  computed: {
    orders() {
      return this.$store.getters.order
    },
    successUpdatePassword() {
      return this.$store.getters.successUpdatePassword
    },
    isUpdated() {
      return this.$store.getters.isUpdated
    },
  },

  watch: {
    async isUpdated(newIsUpdated) {
      if(newIsUpdated) {
        await this.$store.dispatch('getUserData')
        this.showInfo()
      }
    },
    successUpdatePassword(newSuccessUpdatePassword) {
      if(newSuccessUpdatePassword) {
        this.showInfo()
      }
    },
  },

  methods: {
    showInfo() {
      this.toggleProfile = true
      this.toggleEdit = false
      this.togglePassword = false
    },
    showEdit() {
      this.toggleProfile = false
      this.toggleEdit = true
      this.togglePassword = false
    },
    showPassword() {
      this.toggleProfile = false
      this.toggleEdit = false
      this.togglePassword = true
    },
  },

  async created() {
    await this.$store.dispatch('getOrder');
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#profile .container-fluid{
  margin-left: 4.1rem;
  margin-right: 6.5rem;
  width: inherit;
}
.section-header{
  margin-bottom: 3.2rem;
}
.orders{
  border-right: 1px solid #D7D7D7;
  padding-bottom: 38rem;
  margin-bottom: 4.9rem;
}
.order-header{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: .4rem;
  padding-right: 4.7rem;
  padding-top: .7rem;
  direction: rtl;
}
.order-title{
  text-align: right;
  font-size: .8rem;
  font-weight: bold;
}
.order-body{
      border-bottom: 1px solid #D7D7D7;
}
.profile-header{
  padding-top: .7rem;
  margin-bottom: 2.25rem;
}
.profile-title{
  text-align: right;
  font-weight: bold;
  font-size: .8rem;
}
.profile-footer{
  padding-top: 2rem;
  text-align: right;
}
.profile-footer a{
  display: block;
  margin-bottom: .9rem;
  font-size: .8rem;
  color: #000;
  font-weight: bold;
  text-decoration: underline;
}
.profile-footer a:hover{
  text-decoration: none;
}
.table-img{
  width: 5rem;
}
.row-details td{
  border-top: none;
}

#profile .order-header .btn{
  margin-bottom: 0;
  font-family: 'Varela Round', sans-serif;
  font-size: .8rem;
  font-weight: normal;
}
@include media-breakpoint-down(sm) {
  #profile .container-fluid{
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .orders{
    padding-bottom: 5rem;
  }
  .site-section{
    padding-top: 3.1rem;
  }
  #profile .section-title{
    font-size: 1.4rem;
  }
  .section-header{
    margin-bottom: .6rem;
  }
  .section-body > .row{
    flex-direction: column-reverse;
  }
  .profile-title{
    font-size: .9rem;
  }
  .profile-header{
    margin-bottom: 1rem;
  }
  .profile-footer{
    padding-top: 1rem;
  }
  .orders{
    border-right: none;
  }
  .order-header{
    padding-right: 0;
    margin-bottom: 1rem;
  }
  .order-title{
    font-size: .9rem;
  }
}
</style>
