<template>
  <div>
    <div class="profile-header">
      <h4 class="profile-title">
        שינוי סיסמא
      </h4>
    </div>

    <div class="profile-password">
      <form action="/" method="post" class="gong-form" @submit.prevent="checkData">
        <div class="form-group">
          <input type="password" name="password" v-on:focus="deleteErrorServer" id="password" class="form-control"
                 placeholder="סיסמה נוכחית" v-model.trim="password">
          <div class="invalid-feedback" v-if="$v.password.required === false && send">שדה זה הינו חובה</div>
        </div>

        <div class="form-group">
          <input type="password" name="newPassword" v-on:focus="deleteErrorServer" id="newPassword" class="form-control"
                 placeholder="סיסמה חדשה" v-model.trim="newPassword">
          <div class="invalid-feedback" v-if="$v.newPassword.required === false && send">שדה זה הינו חובה</div>
        </div>

        <div class="form-group">
          <input type="password" name="rePassword" v-on:focus="deleteErrorServer" id="rePassword" class="form-control"
                 placeholder="תאשר סיסמא חדשה" v-model.trim="rePassword">
          <div class="invalid-feedback" v-if="$v.rePassword.required === false && send">שדה זה הינו חובה</div>
          <div class="invalid-feedback" v-if="$v.rePassword.sameAsPassword === false && send">הסיסמאות אינם תואמות</div>
        </div>

        <div class="error-server" v-if="error">{{error}}</div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-block">
            שמור שינויים
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'ProfilePassword',

  data() {
    return {
      password: '',
      newPassword: '',
      rePassword: '',
      send: false,
    }
  },

  computed: {
    error() {
      return this.$store.getters.errorUpdatePassword
    },
  },

  validations: {
    password: { required },
    newPassword: { required },
    rePassword: { required, sameAsPassword: sameAs('newPassword') },
  },

  methods: {
    deleteErrorServer() {
      this.$store.commit('clearErrorUpdatePassword', '')
    },
    checkData() {
      this.$v.$touch()
      this.send = true
      if (this.$v.$invalid) {
        return false
      }
      this.savePassword()
    },

    async savePassword() {
      let form = new FormData()
      form.append('password', this.password)
      form.append('newPassword', this.newPassword)
      form.append('rePassword', this.rePassword)

      await this.$store.dispatch('updatePasswordUser', {form})
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.gong-form {
  text-align: right;
}
.gong-form .form-row {
  margin-bottom: 1.5rem;
}
.gong-form label {
  font-weight: bold;
  font-size: .9rem;
}
.gong-form .form-group {
  margin-bottom: 1.1rem;
}
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.profile-header {
  padding-top: .7rem;
  margin-bottom: 2.25rem;
}
.profile-title {
  text-align: right;
  font-weight: bold;
  font-size: .8rem;
}
.error-server {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  text-align: right;
}

@include media-breakpoint-down(sm) {
  .gong-form .form-group .form-control {
    height: 3.4rem;
    font-size: .8rem;
  }
  #innerLayout .form-group .btn {
    height: inherit;
    font-size: 1rem;
    line-height: 2;
  }
}
</style>
